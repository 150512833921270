/**
 * Created by Shawn Matthews @shawnmmatthews on 6/8/17.
 */
import React from 'react'
import BannerImage from './BannerImage'

export default function Banner({ bannerImage, theme, title }) {
  return (
    <div className="relative">
      <BannerImage imageInfo={bannerImage}>
        <div className="absolute bottom-0 md/lg:top-0 w-full">
          <div className="mx-auto w-11/12 xs:w-3/5 sm:w-1/2 pb-4 xs:pb-16 sm:pb-16 md/lg:py-8 md/lg:mx-0 md:px-8 lg:px-16 lg:py-16 xl:px-24 xl:py-24">
            <h1 className="uppercase text-white leading-none pb-3 font-header text-xl sm:text-2xl xl:text-3xl 2xl:text-4xl font-thin">
              {theme}
            </h1>
            <hr className="m-0 h-1/2 w-full md:w-72 bg-white border-none" />
            <h1 className="uppercase text-white leading-snug pt-3 font-header text-3xl sm:text-4xl xl:text-5xl 2xl:text-6xl leading w-full">
              {title}
            </h1>
          </div>
        </div>
      </BannerImage>
    </div>
  )
}
