import React from 'react'
import PropTypes from 'prop-types'
import SVGBorderButton from './SVG/SVGBorderButton'

const DiscoverMore = ({ content }) => {
  const { title, body, link, linklabel } = content
  return (
    <>
      <h1 className="font-header text-3xl text-blue font-header uppercase pb-2">
        {title}
      </h1>
      <p className="font-body text-black-darker pb-8 m-auto">{body}</p>
      <SVGBorderButton
        label={linklabel}
        to={link}
        bgColor="#189CD8"
        bgColorHover="#9AC5E9"
        borderColor="#189CD8"
        borderColorHover="#189CD8"
        textColor="white"
        textColorHover="#243D91"
      />
    </>
  )
}

DiscoverMore.propTypes = {
  content: PropTypes.object,
}

export default DiscoverMore
