import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Markdown from 'react-markdown'
import CommonLink from '../components/CommonLink'

export const EventTemplate = ({
  title,
  link,
  eventdate,
  location,
  description,
}) => (
  <div className="px-4 border-solid font-body bg-purple-lightest border-l-3 border-purple">
    <CommonLink to={link}>
      <h4 className="text-xl font-semibold uppercase font-body text-purple">
        {title}
      </h4>
    </CommonLink>
    {eventdate ? <p className="text-lg font-body text-purple">{eventdate}</p> : null}
    {location ? <p className="font-body text-purple">{location}</p>: null }
    <Markdown className="font-body text-black-darker" source={description} />
  </div>
)

EventTemplate.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  eventdate: PropTypes.string,
  location: PropTypes.string,
  description: PropTypes.string,
}

const Event = ({ data }) => {
  const { markdownRemark: event } = data

  return (
    <EventTemplate
      title={event.frontmatter.title}
      link={event.frontmatter.link}
      eventdate={event.frontmatter.eventdate}
      location={event.frontmatter.location}
      description={event.frontmatter.description}
    />
  )
}

Event.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Event

export const pageQuery = graphql`
  query EventByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        link
        eventdate
        location
        description
      }
    }
  }
`
