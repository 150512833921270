import React from 'react'
import Markdown from 'react-markdown'
import PropTypes from 'prop-types'

const Section = ({ content, className, titleStyle, bodyStyle }) => {
  const { title, body } = content
  return (
    <>
      <div className={`w-full ${className}`}>
        <h2 className={`font-header text-3xl uppercase pb-2 ${titleStyle}`}>
          {title}
        </h2>
        <Markdown
          className={`${bodyStyle} font-body markdown-section`}
          source={body}
        />
      </div>
    </>
  )
}

Section.propTypes = {
  content: PropTypes.object,
  className: PropTypes.string,
  titleStyle: PropTypes.string,
  bodyStyle: PropTypes.string,
}

Section.defaultProps = {
  titleStyle: 'text-pink',
  bodyStyle: 'text-black-darker',
}

export default Section
