import React from 'react'
import Markdown from 'react-markdown'

const SectionWithAside = ({ content }) => {
  const { title, body, asidetitle, asidebody, bodyStyle } = content
  return (
    <div className="flex flex-col-reverse md:block">
      <div className="xs:mt-12 md:mt-0 md:float-right mx-4 w-5/6 md:w-1/3 md:ml-8 lg:ml-10 md:mb-4 lg:mb-6 md:mr-0 px-6 border-l-3 border-orange border-solid">
        <h2 className="pb-1 leading-none uppercase font-bold text-orange text-l">
          {asidetitle}
        </h2>
        <p className="font-body text-black-darker italic leading-relaxed">
          {asidebody}
        </p>
      </div>
      <div>
        <h2 className="font-header text-pink text-3xl uppercase pb-2">
          {title}
        </h2>
        <Markdown
          className={`${bodyStyle} font-body markdown-section`}
          source={body}
        />
      </div>
    </div>
  )
}

export default SectionWithAside
