import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

// SAVE FOR LATER USE
// const BreadcrumbLink = props => (
//   <Link
//     {...props}
//     getProps={({ isCurrent }) =>
//       // the object returned here is passed to the
//       // anchor element's props
//       ({
//         className: isCurrent
//           ? 'text-black-light pointer-events-none'
//           : 'text-blue underline hover:text-orange',
//       })
//     }
//   />
// );

const Breadcrumb = props => {
  const { to, label } = props
  return (
    <div className="flex justify-center pt-4">
      <Link
        className="font-body text-blue hover:underline hover:text-orange"
        to="/"
      >
        Home
      </Link>
      <span className="font-body text-black-darker px-2"> > </span>
      <Link
        className="font-body text-black-lighter pointer-events-none"
        to={`/${to}`}
      >
        {label}
      </Link>
    </div>
  )
}

Breadcrumb.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
}

export default Breadcrumb
