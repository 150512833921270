import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Accordion from '../components/Accordion'
import DiscoverMore from '../components/DiscoverMore'
import Layout from '../components/Layout'
import Breadcrumb from '../components/Breadcrumb'
import Banner from '../components/Banner'
import SectionWithAside from '../components/SectionWithAside'
import Section from '../components/Section'
import Container from '../components/Container'

export const InPracticePage2Template = ({
  bannerimage,
  title,
  theme,
  sectionwithaside,
  secondsection,
  accordionsection,
  discovermore,
}) => (
  <>
    <Banner bannerImage={bannerimage} title={title} theme={theme} />
    <Breadcrumb to="in-practice" label="In Practice" />
    <Container className="pb-10 md:pb-0 pt-12 sm:pt-16 md:pt-20 flex flex-col lg:flex-row">
      <SectionWithAside content={sectionwithaside} />
    </Container>
    <Container>
      <Section content={secondsection} />
    </Container>
    <Container className="pt-8 sm:pt-10 md:pt-12">
      <Section content={accordionsection} />
    </Container>
    <Container className="flex flex-col pt-12 sm:pt-16 md:pt-20 pb-12 sm:pb-16 md:pb-20">
      {accordionsection.accordionset.map((set, index) => {
        console.log(set)
        return (
          <div className="flex flex-col xl:flex-row pb-8 px-0 md:px-20">
            <div className={`p-6 bg-${set.color}-lighter w-full xl:w-1/4`}>
              <h2 className={`font-header text-2xl text-${set.color} pb-3`}>
                {set.title}
              </h2>
              <p className="font-body text-black-darker">{set.body}</p>
            </div>
            <div className="w-full xl:w-3/4">
              <Accordion
                accordionitems={set.accordionitems}
                color={set.color}
                performancestatus
              />
            </div>
          </div>
        )
      })}
    </Container>
    <Container
      outerClassName="bg-black-discovermore text-center"
      className="pb-2 py-12 sm:py-16"
    >
      <DiscoverMore content={discovermore} />
    </Container>
  </>
)

InPracticePage2Template.propTypes = {
  bannerimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  theme: PropTypes.string,
  sectionwithaside: PropTypes.object,
  secondsection: PropTypes.object,
  accordionsection: PropTypes.object,
  discovermore: PropTypes.object,
}

const InPracticePage2 = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout
      ogtitle={frontmatter.ogtitle}
      ogdescription={frontmatter.ogdescription}
      ogimage={frontmatter.ogimage}
    >
      <InPracticePage2Template
        bannerimage={frontmatter.bannerimage}
        title={frontmatter.title}
        theme={frontmatter.theme}
        sectionwithaside={frontmatter.sectionwithaside}
        secondsection={frontmatter.secondsection}
        accordionsection={frontmatter.accordionsection}
        discovermore={frontmatter.discovermore}
      />
    </Layout>
  )
}

InPracticePage2.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default InPracticePage2

export const pageQuery = graphql`
  query InPracticePage2Template($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        opengraph {
          ogtitle
          ogdescription
          ogimage {
            childImageSharp {
              fluid(maxWidth: 300, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        title
        theme
        bannerimage {
          alt
          desktopimage {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          mobileimage {
            childImageSharp {
              fluid(maxWidth: 768, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        sectionwithaside {
          title
          body
          asidetitle
          asidebody
        }
        secondsection {
          title
          body
        }
        accordionsection {
          title
          body
          accordionset {
            title
            body
            color
            accordionitems {
              button
              body
            }
          }
        }
        discovermore {
          title
          body
          link
          linklabel
        }
      }
    }
  }
`
