import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CommonLink from '../CommonLink'

const svgContainer = {
  position: 'relative',
  height: '53px',
  width: '100%',
}

const svgStyle = {
  position: 'absolute',
  height: '100%',
  width: '100%',
  left: 0,
  top: 0,
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  strokeLinejoin: 'round',
  strokeMiterlimit: '1.41421',
}

const textStyle = {
  fontFamily: 'Oswald',
  textTransform: 'uppercase',
  fontWeight: '500',
  fontSize: '1.5rem',
}

const SVGBorderButton = props => {
  const [hover, setHover] = useState(false)
  const {
    to,
    bgColor,
    bgColorHover,
    borderColor,
    borderColorHover,
    textColor,
    textColorHover,
    label,
  } = props
  return (
    <CommonLink to={to}>
      <button
        onMouseOver={() => setHover(true)}
        onFocus={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onBlur={() => setHover(false)}
        type="button"
        className="w-40 "
      >
        <div style={svgContainer}>
          <svg
            viewBox="0 0 185 62"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve"
            className="overflow-y-hidden"
            style={svgStyle}
          >
            <path
              d="M156.404,-0.02l4.122,-5.905l18.186,36.937l-18.186,36.936l-4.122,-5.936l-156.41,0l0,-62.032l156.41,0Z"
              fill={hover ? bgColorHover : bgColor}
            />
            <path
              d="M154.854,62.012l1.55,0l4.124,5.937l18.186,-36.936l-18.186,-36.937l-3.34,5.272l-2.82,0.387l0.486,-30.57l30.146,61.835l-30.146,61.835l0,-30.823Z"
              fill={hover ? borderColorHover : borderColor}
            />
            <rect
              x="-0.123"
              y="-0.053"
              width="166.682"
              height="5.045"
              fill={hover ? borderColorHover : borderColor}
            />
            <rect
              x="-0.223"
              y="56.999"
              width="166.781"
              height="5.046"
              fill={hover ? borderColorHover : borderColor}
            />
            <rect
              x="0.001"
              y="-0.053"
              width="4.99"
              height="57.276"
              fill={hover ? borderColorHover : borderColor}
            />
            <text
              style={textStyle}
              textAnchor="middle"
              x="47%"
              y="65%"
              fill={hover ? textColorHover : textColor}
            >
              {label}
            </text>
          </svg>
        </div>
      </button>
    </CommonLink>
  )
}

SVGBorderButton.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  bgColorHover: PropTypes.string.isRequired,
  borderColor: PropTypes.string.isRequired,
  borderColorHover: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  textColorHover: PropTypes.string.isRequired,
}

export default SVGBorderButton
