/* eslint-disable no-constant-condition */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-markdown'
import SVGChevron from './SVG/SVGChevron'
import SVGBorderButton from './SVG/SVGBorderButton'
import { EventTemplate } from '../templates/EventTemplate'

const Accordion = ({ accordionitems, color, performancestatus }) => {
  // eslint-disable-next-line no-restricted-globals
  let activeId = ''

  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-restricted-globals
    if (location.hash) {
      // eslint-disable-next-line no-restricted-globals
      activeId = location.hash.substring(1)
    }
  }

  function getTheme(title = '_') {
    if (
      title === 'urban_center_housing' ||
      title === 'urban_center_employment' ||
      title === 'regional_population_-_weighted_density'
    ) {
      return 'place'
    }
    if (
      title === 'non-single_occupant_vehicle_(sov)_travel_to_work' ||
      title === 'vehicle_miles_traveled_(vmt)' ||
      title === 'travel_time_variation_(ttv)' ||
      title === 'person_delay' ||
      title === 'traffic_fatalities'
    ) {
      return 'mobility'
    }
    if (
      title === 'surface_transportation_greenhouse_gas_(ghg)' ||
      title === 'protected_open_space' ||
      title === 'housing_in_high-risk_areas' ||
      title === 'employment_in_high-risk_areas'
    ) {
      return 'environment'
    }
    if (title === 'residents_living_in_affordable_locations') {
      return 'livability'
    }
    if (
      title === 'regional_employment' ||
      title === 'housing_near_high-frequency_or_rapid_transit' ||
      title === 'employment_near_high-frequency_or_rapid_transit'
    ) {
      return 'vitality'
    }
  }

  const indexLength = accordionitems.length
  return (
    <>
      {accordionitems.map((item, index) => {
        return (
          <>
            {item.button ? (
              <AccordionItem
                id={item.button.replace(/ /g, '_')}
                title={item.button}
                borderColor={color}
                buttonColor={`bg-${color}`}
                buttonActiveColor={`bg-${color}-light`}
                buttonTextColor="text-white"
                buttonActiveTextColor={`text-${color}`}
                backgroundColor={`bg-${color}-lightest`}
                index={index}
                indexLength={indexLength}
                activeId={activeId}
              >
                {performancestatus && (
                  <>
                    <Markdown className="markdown-section" source={item.body} />
                    <div className="flex justify-center pt-2">
                      <SVGBorderButton
                        label="Observations"
                        to={`/explore/${getTheme(
                          item.button.replace(/ /g, '_').toLowerCase()
                        )}/#${item.button.replace(/ /g, '_').toLowerCase()}`}
                        bgColor="#189CD8"
                        bgColorHover="#9AC5E9"
                        borderColor="#189CD8"
                        borderColorHover="#189CD8"
                        textColor="white"
                        textColorHover="#243D91"
                      />
                    </div>
                  </>
                )}
                {!performancestatus && 
                  <ul>
                    {item.posts &&
                      item.posts
                        .map(( post, id ) => (
                          <li className="mb-4" key={id}>
                            <EventTemplate
                              title={post.title}
                              link={post.link}
                              eventdate={post.eventdate}
                              location={post.location}
                              description={post.description}
                            />
                          </li>
                    ))}
                  </ul>
                }
              </AccordionItem>
            ) : <p>test</p>}
          </>
        )
      })}
    </>
  )
}

Accordion.propTypes = {
  accordionitems: PropTypes.array,
  color: PropTypes.string,
}

export default Accordion

function AccordionItem(props) {
  const {
    id,
    title,
    children,
    borderColor,
    buttonColor,
    buttonActiveColor,
    buttonTextColor,
    buttonActiveTextColor,
    backgroundColor,
    index,
    indexLength,
    activeId,
  } = props
  const [activeState, setActiveState] = useState('')
  const [heightState, setHeightState] = useState('0px')
  const [rotateState, setRotateState] = useState(
    'ml-auto transition-transform-ease'
  )
  const content = useRef(null)

  function toggleAccordion() {
    setActiveState(
      activeState === '' ? 'buttonActiveColor buttonActiveTextColor' : ''
    )
    setHeightState(
      activeState === 'buttonActiveColor buttonActiveTextColor'
        ? '0px'
        : `${content.current.scrollHeight}px`
    )
    setRotateState(
      activeState === 'buttonActiveColor buttonActiveTextColor'
        ? 'ml-auto transition-transform-ease'
        : 'ml-auto transition-transform-ease rotate-180'
    )
  }

  useEffect(() => {
    if (id === activeId) {
      toggleAccordion()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex flex-col">
      <button
        id={id}
        className={`${buttonColor} ${buttonTextColor} font-header py-2 px-3 flex border-4 border-${borderColor} b-none outline-none hover:${buttonActiveColor} hover:${buttonActiveTextColor} ${activeState}`}
        onClick={toggleAccordion}
        type="button"
      >
        <p className="w-64 text-xl uppercase sm:w-full sm:text-left font-header">
          {title}
        </p>
        <SVGChevron className={`${rotateState}`} fill="#FFF" />
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${heightState}` }}
        className={`${backgroundColor} font-body overflow-hidden transition-max-height-ease ${
          indexLength === index + 1 ? 'pb-0' : 'pb-4'
        }`}
      >
        <div className="p-4 font-body text-black-darker">{children}</div>
      </div>
    </div>
  )
}

AccordionItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  borderColor: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  buttonActiveColor: PropTypes.string.isRequired,
  buttonTextColor: PropTypes.string.isRequired,
  buttonActiveTextColor: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  indexLength: PropTypes.number.isRequired,
  activeId: PropTypes.string,
}
